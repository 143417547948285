import { useState } from "react";

import helpers from "@/lib/helpers";

import { ScrollArea } from "@/_v2/components/ui/scroll-area";
import SearchResults from "@/layout/main/SearchResults";
import { useUser } from "@/layout/Context";

import SearchInput from "./layout-header-search-input";

export const Search = () => {
  const qs = helpers.qsFromLocation(location); // keyword search /route?q=<search terms>
  const defaultKeywords = Array.isArray(qs.q) ? "" : qs.q || "";

  const [keywords, setKeywords] = useState(defaultKeywords);
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [searchError, setSearchError] = useState(null);

  const { permissions } = useUser();

  if (!permissions?.search) {
    return null;
  }

  return (
    <>
      <SearchInput
        setSearchResults={setSearchResults}
        setSearchError={setSearchError}
        keywords={keywords}
        setKeywords={setKeywords}
        setSearchLoading={setSearchLoading}
      />

      {keywords ? (
        <>
          <div className="absolute z-30 top-11 left-8 w-[90%] lg:w-[60%] h-[calc(100%-60px)]">
            <ScrollArea className="z-30 rounded-md drop-shadow-md shadow-sm dark:border dark:border-border h-full">
              <SearchResults
                searchResults={searchResults}
                setSearchResults={setSearchResults}
                keywords={keywords}
                setKeywords={setKeywords}
                searchLoading={searchLoading}
                searchError={searchError}
              />
            </ScrollArea>
          </div>
          <div
            className="absolute z-20 top-[40px] left-0 !ml-0 w-[100vw] h-[100vh] bg-black/20"
            onClick={() => {
              setKeywords("");
              setSearchResults([]);
              setSearchLoading(false);
            }}
          />
        </>
      ) : null}
    </>
  );
};
