import { Route, Switch } from "react-router-dom";
import "./main.css";

import { RequirePermission } from "@/common/RequirePermission";

import { LayoutV2 } from "./layout/layout";
import { SRFeatureFlagsPage } from "./pages/sr-feature-flags/SRFeatureFlagsPage";

// import { HomePage } from "./pages/home/home-page";
// import { OrganizationsPage } from "./pages/organizations/OrganizationsPage";

export const RouterV2 = () => {
  return (
    <LayoutV2>
      <Switch>
        {/* <Route exact path="/v2" render={HomePage} />

        <Route exact path="/v2/organizations" render={OrganizationsPage} /> */}

        <Route
          exact
          path="/v2/sr-feature-flags"
          render={() => (
            <RequirePermission permission="sr_feature_flags_view">
              <SRFeatureFlagsPage />
            </RequirePermission>
          )}
        />
      </Switch>
    </LayoutV2>
  );
};
