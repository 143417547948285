import { Link, useLocation } from "react-router-dom";
import { ExitIcon, HamburgerMenuIcon } from "@radix-ui/react-icons";
import { Drawer } from "vaul";

import { cn } from "@/_v2/utils";
import { useSidenavStore } from "@/_v2/state/sidenav";
import { ScrollArea } from "@/_v2/components/ui/scroll-area";

import { useUser } from "@/layout/Context";
import { useIsDesktop } from "@/hooks/breakpoints";
import Helpers from "@/lib/helpers";

import { NavigationItem, navigationLinks } from "../navigation-links";

import { LayoutSidenavThemeSelect } from "./layout-sidenav-theme-select";

export const LayoutSidenav = () => {
  const { permissions } = useUser();
  const isDesktop = useIsDesktop();
  const { sidenavOpen, setSidenavOpen } = useSidenavStore();

  if (isDesktop) {
    return null;
  }

  const filteredLinks = navigationLinks
    .filter((link) => !link.permission || !!permissions[link.permission])
    .map((link) => {
      return <SidenavLink key={link.to} {...link} />;
    });

  return (
    <Drawer.Root
      direction="left"
      open={sidenavOpen}
      onOpenChange={setSidenavOpen}
    >
      <Drawer.Trigger>
        <HamburgerMenuIcon className="size-5 text-muted-foreground" />
      </Drawer.Trigger>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-background/30 backdrop-blur-[1px]" />
        <Drawer.Content className="-left-1 -top-1 bottom-0 fixed z-10 flex outline-none">
          <ScrollArea className="bg-background rounded-r-lg w-[240px] grow mt-2 mr-2 mb-2 flex flex-col border border-border shadow-lg">
            <ul className="p-3 pl-4">
              <p className="font-bold text-sm ml-1 pb-2">SupportRent</p>
              {filteredLinks}
            </ul>

            <section className="p-3">
              <p className="font-bold text-sm ml-1">Account</p>
              <button
                className={cn(
                  "flex space-x-2 p-2 justify-start items-center rounded-sm text-base font-semibold"
                )}
                onClick={() => {
                  Helpers.auth.logout();
                  setSidenavOpen(false);
                }}
              >
                <span>
                  <ExitIcon className="size-5" />
                </span>
                <p>Logout</p>
              </button>
            </section>

            <section className="p-3">
              <p className="font-bold text-sm ml-1 mb-1">Settings</p>
              <LayoutSidenavThemeSelect />
            </section>
          </ScrollArea>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

const SidenavLink = ({ to, label, icon: Icon }: NavigationItem) => {
  const setSidenavOpen = useSidenavStore((state) => state.setSidenavOpen);
  const location = useLocation();
  const isActive = to === location.pathname;

  return (
    <Link
      to={to}
      className={cn(
        "flex space-x-2 p-2 justify-start items-center rounded-sm text-base font-semibold hover:bg-accent transition-colors",
        {
          "bg-accent": isActive,
        }
      )}
      onClick={() => setSidenavOpen(false)}
    >
      {Icon && (
        <span>
          <Icon size={20} className="size-5" />
        </span>
      )}
      <p>{label}</p>
    </Link>
  );
};
